<template>
  <div class="hello">
    <div v-html="locale.prime_desc"></div>
    <Accordion :items="benefits" />
    <div v-html="locale.price_desc"></div>
    <Accordion :items="turbos" />
    <div v-html="locale.turbo_price"></div>
  </div>
</template>

<script setup lang="ts">
  import type { AccordionArray } from '@/types/accordion'
  import type { Description } from '@/types/description'

  const props = defineProps<{
    description: {
      [key: string]: Description;
    }
  }>();

  const { description: locale } = props;

  const turbos = locale.turbos as AccordionArray;
  const benefits = locale.benefits as AccordionArray;
</script>

<style scoped></style>
