<template>
  <div>
    <h3>{{ locale.title }}</h3>
    <Accordion :items="commands" />
  </div>
</template>

<script setup lang="ts">
  import type { AccordionArray } from '@/types/accordion'
  import type { Description } from '@/types/description'


  const props = defineProps<{
    description: {
      [key: string]: Description;
    }
  }>();

  const { description: locale } = props;

  const commands = locale.commands as AccordionArray;
</script>

<style scoped></style>
