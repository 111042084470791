import { defineStore, storeToRefs } from 'pinia';
import { useWebAppNavigation } from 'vue-tg'

import { useControllerStore } from '@/stores/controller'

import type { Invoice, Payment } from '@/types/payment';

export const usePaymentStore = defineStore('paymment', () => {
  const payment = ref({})

  const setPaymentData = (data: Payment) => Object.assign(payment.value, data)

  const setInvoice = (invoice: Invoice) => {
    const { linkInitData } = storeToRefs(useControllerStore())

    const { openInvoice } = useWebAppNavigation()
    const { setServiceData } = useControllerStore()

    openInvoice(invoice.result, (status) => {
      if (status === 'paid') {
        setServiceData({ ...payment.value, status: 'paid' }, '');
      } else if (status === 'cancelled' || status === 'failed') {
        setServiceData({ ...linkInitData.value });
      }
    })
  }

  return {
    payment,
    setPaymentData,
    setInvoice
  }
})
