<template>
  <BackButton @click="back" />
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { BackButton } from 'vue-tg'
  import { router } from '@inertiajs/vue3'

  import { useControllerStore } from "@/stores/controller";

  interface RequestPayload {
    [key: string]: any;
  }

  const { setup } = storeToRefs(useControllerStore())

  const props = defineProps<{
    href: string,
    data?: RequestPayload
  }>()

  const back = () =>  {
    setup.value.loaded = false

    // TODO: need more time to make logic for a back button
    router.visit(props.href, { data: props.data || {}, preserveScroll: false })
  }
</script>

<style scoped lang="scss"></style>
