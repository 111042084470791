<template>
  <Link :href :data :as @click="open">
    <slot></slot>
  </Link>
</template>

<script setup lang="ts">
  import { Link } from '@inertiajs/vue3'

  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';

  const { setup, linkInitData } = storeToRefs(useControllerStore())

  const props = defineProps<{
    href: string
    data?: object
    as?: string
  }>()

  const open = () => {
    setup.value.loaded = false
    linkInitData.value = props.data || {}
  }
</script>

<style scoped lang="scss">
  button {
    display: block;
    width: 100%;
    max-width: 280px;
    padding: var(--btn-padding);
    border: 0px;
    align-self: center;
    border-radius: var(--radius-half);
    background-color: var(--btn-primary-color);
    font-family: 'DMSans', sans-serif;
    color: var(--btn-text-color);
    font-size: var(--btn-text-size);
    text-align: center;
  }
</style>
