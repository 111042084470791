<template>
  <main v-if="setup.loaded && setup.locale"
    @click="(event) => copyCommandTextToBuffer(event, `${ setup.base_locale.copy }`)"
  >
    <BaseNotification v-if="setup.locale?.message" />
    <slot v-else ></slot>
  </main>
  <Preloader v-else />
</template>

<script setup lang="ts">
  import { onMounted } from 'vue';
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';

  import { setThemeType, setCustomColorTheme } from '@/composables/templateSettings';
  import { copyCommandTextToBuffer } from '@/composables/notification';
  import { watchScrollTo } from '@/composables/watchers/watchScrollTo';

  import type { InitControllerData } from '@/types/controller';

  const { setup, controller } = storeToRefs(useControllerStore())
  const { setControllerData, setInitControllerData } = useControllerStore()

  const props = defineProps<{
    initProps: InitControllerData;
  }>()

  onMounted(() => {
    setInitControllerData(props.initProps);
    setControllerData();
    setThemeType();
    setCustomColorTheme();
  });

  watchScrollTo();
</script>

<style lang="scss"></style>
