import { defineStore } from 'pinia';
import { useWebApp } from 'vue-tg';

import { usePaymentStore } from '@/stores/payment'
import { setTemplateLanguage } from '@/composables/templateSettings';
import { fetchData } from '@/composables/fetchData'

import type { Setup, InitControllerData, IData } from '@/types/controller'

export const useControllerStore = defineStore('controller', () => {
  const { initData } = useWebApp()
  const { setInvoice } = usePaymentStore()

  const token = ref('');
  const controller = ref('');
  const linkInitData = ref({});
  const options: IData = ref({});

  const setup = ref<Setup>({
    loaded: false,
    locale: {},
    base_locale: {},
    language_code: '',
    data: {}
  });

  const setInitControllerData = async (props: InitControllerData) => {
    controller.value = props.controller

    if (props.data) {
      options.value = props.data
    }
  };

  const setSessionToken = async () => {
    let sessionToken = sessionStorage.getItem('jwt_token')

    if (!sessionToken) {
      const session = await fetchData('/sessions', initData, token)
      sessionStorage.setItem('jwt_token', session.token)
      sessionToken = session.token
    }

    token.value = sessionToken ?? ''
  }

  const setControllerData = async () => {
    if (initData === '') {
      options.value.empty_data = true;
    }

    await setSessionToken();
    const data = await fetchData(`/${controller.value}/set_user`, initData, token, options.value)

    Object.assign(setup.value, data);
    setTemplateLanguage(setup.value.language_code);
  }

  const setServiceData = async (options: IData, path = 'set_user') => {
    setup.value.loaded = false

    const data = await fetchData(`/${controller.value}/${path}`, initData, token, options)
    Object.assign(setup.value, data);

    if (setup.value.data.invoice) {
      setInvoice(setup.value.data.invoice);
    }
  }

  return {
    token,
    controller,
    linkInitData,
    setup,
    setInitControllerData,
    setControllerData,
    setServiceData
  }
})
