<template>
  <div class="message" :class="errorClass">
    <Logo />
    <h1>{{ setup.locale.message.title }}</h1>
    <Section v-if="message">
      <div v-html="message"></div>
    </Section>
    <Link v-if="setup.locale.message.link" :href="setup.locale.message.href" :as="'button'"
      :data="{ ...setup.locale.message.data }"
    >
      {{ setup.locale.message.link.text }}
    </Link>
    <ButtonCloseApp v-else />
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'

  const { setup } = storeToRefs(useControllerStore())
  const message = computed(() => (setup.value.locale.error) ? setup.value.base_locale.error_message : setup.value.locale.message.description);
  const errorClass = computed(() => (setup.value.locale.error) ? 'error' : '');
</script>

<style scoped lang="scss">
  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100dvh - 2rem);
    text-align: center;
    &.error div {
      text-align: center;
    }

    img {
      height: 200px;
      width: auto;
    }
    h1 {
      margin-bottom: 0;
      font-size: 28px;
      text-align: center;
    }
    h4 {
      margin-bottom: 0;
      font-size: 28px;
    }
    section {
      margin-top: var(--space);
    }
    div {
      display: flex;
      flex-direction: column;
      gap: var(--space);
      text-align: start;
    }
    button {
      margin-top: var(--space);
    }
  }
</style>
