<template>
  <Main :initProps>
    <Payments>
      <template v-slot:title>
        <h1>{{ setup.locale.h1 }}</h1>
      </template>
      <template v-slot:profile>
        <ProfileInfo v-if="setup.data?.group?.photo"
          :img="setup.data.group.photo"
          :title="setup.data.group.title"
        />
      </template>
      <template v-slot:general>
        <Section :data="setup.locale" v-slot:general />
      </template>
      <template v-slot:payment-cards>
        <CardContainer :data="setup.locale.methods_data">
          <Card v-for="(item, index) in setup.locale.methods" :item :index :name="'payment'" @change="changePayment"/>
        </CardContainer>
      </template>
      <template v-slot:amount>
        <Section :data="setup.locale.input">
          <InputNumber name="amount" :placeholder :min required />
        </Section>
      </template>
      <template v-slot:comment>
        <Section>
          <h4>{{ setup.locale.comment }}</h4>
          <Input name="comment" />
        </Section>
      </template>
      <template v-slot:anonymous>
        <Section v-if="setup.locale.anonymous" :class="'switch'">
          <h4>{{ setup.locale.anonymous }}</h4>
          <Switch :name="'anonymous'" />
        </Section>
      </template>
    </Payments>
  </Main>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';

  import type { InitControllerData } from '@/types/controller';

  const { setup } = storeToRefs(useControllerStore())
  const initProps = defineProps<InitControllerData>()

  const min = ref('0');
  const placeholder = ref('')

  watch(() => setup.value.locale, (newLocale) => {
    if (newLocale.input) {
      min.value = newLocale.input.coins.min
      placeholder.value = newLocale.input.coins.placeholder;
    }
  }, { immediate: false });

  const changePayment = ({ target }: { target: HTMLInputElement }) => {
    min.value = setup.value.locale.input[target.value].min.toString()
    placeholder.value = setup.value.locale.input[target.value].placeholder
  }
</script>

<style scoped lang="scss"></style>
