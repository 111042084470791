<template>
  <Main :initProps>
    <ButtonBack :href="'/payments'" />
    <Payments class="primes">
      <template v-slot:title>
        <h1>{{ setup.locale.h1 }}</h1>
      </template>
      <template v-slot:details>
        <Section >
          <h4>{{ setup.locale.details.title }}</h4>
          <div>{{ setup.locale.details.description }}
            <Link href="/instructions" :data="{ scrollTo: { selector: '#wardy-prime', active: true } }">
              {{ setup.locale.details.link_text }}
            </Link>
          </div>
        </Section>
      </template>
      <template v-slot:payment-cards>
        <CardContainer class="payment" :data="setup.locale.methods_data">
          <Card v-for="(item, index) in setup.locale.methods" :item :index :name="'payment'" @change="changePayment"
            :data-label="(item.id === type) ? dataLabel : ''"
          />
        </CardContainer>
      </template>
      <template v-slot:amount-cards>
        <CardContainer class="amount" :data="setup.locale.subscribe_data">
          <Card v-for="(item, index) in setup.data[type]" :item :index :name="'amount'"  @change="changeAmount"/>
        </CardContainer>
      </template>
    </Payments>
  </Main>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';

  import type { InitControllerData } from '@/types/controller';

  const { setup } = storeToRefs(useControllerStore())
  const initProps = defineProps<InitControllerData>()

  const type = ref('coins')
  const dataLabel = ref('')

  watch(() => setup.value.data?.prices, (newPrices) => {
    if (!newPrices) return;
    
    const firstKey = Object.keys(newPrices[type.value])?.[0];
    dataLabel.value = newPrices[type.value][firstKey];
  })

  const changePayment = ( { target }: { target: HTMLInputElement } ) => type.value = target.value;

  const changeAmount = ( { target }: { target: HTMLInputElement } ) => {
    dataLabel.value = setup.value.data.prices[type.value][target.value]
  };
</script>

<style scoped lang="scss">
  .primes {
    h1 {
      margin-bottom: 0;
    }
    .amount {
      order: 1;
      .card {
        :deep(h5) {
          margin-top: 0;
          font-size: 38px;
        }

        :deep(.description) {
          margin-top: 0;
          font-size: 18px;
        }
      }
    }
    .payment {
      order: 2;

      label {
        position: relative;
        &[data-label]:before {
          content: attr(data-label);
          position: absolute;
          top: var(--space);
          right: var(--space);
          font-size: 28px;
          color: #ff0000;
          font-weight: 700;
        }
      }
    }
  }
</style>
