<template>
  <Main :initProps>
    <ButtonCloseApp />
  </Main>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';

  import type { InitControllerData } from '@/types/controller';

  const { setup } = storeToRefs(useControllerStore())
  const initProps = defineProps<InitControllerData>()
</script>

<style lang="scss"></style>
