<template>
    <Form @submit.prevent="submitFormData" class="payments">
      <slot name="title"></slot>
      <slot name="profile"></slot>
      <slot name="general"></slot>
      <slot name="payment_info"></slot>
      <slot name="details"></slot>
      <slot name="prime"></slot>
      <slot name="payment-cards"></slot>
      <slot name="amount"></slot>
      <slot name="amount-cards"></slot>
      <slot name="comment"></slot>
      <slot name="anonymous"></slot>
      <slot name="additional_info"></slot>
      <Button type="submit">{{ setup.locale.submit }}</Button>
    </Form>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  import { usePaymentStore } from '@/stores/payment'

  const { setServiceData } = useControllerStore()
  const { setPaymentData } = usePaymentStore()
  const { setup } = storeToRefs(useControllerStore())

  const submitFormData = (event: Event) => {
    const formData = new FormData(event.target as HTMLFormElement);
    const data = Object.fromEntries(formData.entries());

    if (formData.has('amount')) {
      setPaymentData(data)
      setServiceData(data, '')
    }
  }
</script>

<style scoped lang="scss">
  .payments {
    display: flex;
    flex-direction: column;
    gap: var(--space);
    h1 {
      margin: 0;
    }
    button {
      order: 10; // submit button is always on the bottom of a page
      max-width: 100%;
    }
  }
</style>
