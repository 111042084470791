<template>
    <form @keydown.enter.prevent="hideMobileKeyboard">
      <slot></slot>
    </form>
</template>

<script setup lang="ts">
  import { onMounted, onUnmounted } from 'vue';

  const hideMobileKeyboard = () => {
    (document.activeElement as HTMLInputElement).blur();
    navigator?.virtualKeyboard?.hide!();
  };

  onMounted(() => document.addEventListener('touchmove', hideMobileKeyboard));
  onUnmounted(() => document.removeEventListener('touchmove', hideMobileKeyboard));
</script>

<style scoped lang="scss"></style>
